export const keywords: string[] = [
  // https://docs.microsoft.com/en-us/sql/t-sql/language-elements/reserved-keywords-transact-sql?view=sql-server-ver15
  // standard
  'ADD',
  'ALL',
  'ALTER',
  'AND',
  'ANY',
  'AS',
  'ASC',
  'AUTHORIZATION',
  'BACKUP',
  'BEGIN',
  'BETWEEN',
  'BREAK',
  'BROWSE',
  'BULK',
  'BY',
  'CASCADE',
  'CHECK',
  'CHECKPOINT',
  'CLOSE',
  'CLUSTERED',
  'COALESCE',
  'COLLATE',
  'COLUMN',
  'COMMIT',
  'COMPUTE',
  'CONSTRAINT',
  'CONTAINS',
  'CONTAINSTABLE',
  'CONTINUE',
  'CONVERT',
  'CREATE',
  'CROSS',
  'CURRENT',
  'CURRENT_DATE',
  'CURRENT_TIME',
  'CURRENT_TIMESTAMP',
  'CURRENT_USER',
  'CURSOR',
  'DATABASE',
  'DBCC',
  'DEALLOCATE',
  'DECLARE',
  'DEFAULT',
  'DELETE',
  'DENY',
  'DESC',
  'DISK',
  'DISTINCT',
  'DISTRIBUTED',
  'DROP',
  'DUMP',
  'ERRLVL',
  'ESCAPE',
  'EXEC',
  'EXECUTE',
  'EXISTS',
  'EXIT',
  'EXTERNAL',
  'FETCH',
  'FILE',
  'FILLFACTOR',
  'FOR',
  'FOREIGN',
  'FREETEXT',
  'FREETEXTTABLE',
  'FROM',
  'FULL',
  'FUNCTION',
  'GOTO',
  'GRANT',
  'GROUP',
  'HAVING',
  'HOLDLOCK',
  'IDENTITY',
  'IDENTITYCOL',
  'IDENTITY_INSERT',
  'IF',
  'IN',
  'INDEX',
  'INNER',
  'INSERT',
  'INTERSECT',
  'INTO',
  'IS',
  'JOIN',
  'KEY',
  'KILL',
  'LEFT',
  'LIKE',
  'LINENO',
  'LOAD',
  'MERGE',
  'NOCHECK',
  'NONCLUSTERED',
  'NOT',
  'NULL',
  'NULLIF',
  'OF',
  'OFF',
  'OFFSETS',
  'ON',
  'OPEN',
  'OPENDATASOURCE',
  'OPENQUERY',
  'OPENROWSET',
  'OPENXML',
  'OPTION',
  'OR',
  'ORDER',
  'OUTER',
  'OVER',
  'PERCENT',
  'PIVOT',
  'PLAN',
  'PRIMARY',
  'PRINT',
  'PROC',
  'PROCEDURE',
  'PUBLIC',
  'RAISERROR',
  'READ',
  'READTEXT',
  'RECONFIGURE',
  'REFERENCES',
  'REPLICATION',
  'RESTORE',
  'RESTRICT',
  'RETURN',
  'REVERT',
  'REVOKE',
  'RIGHT',
  'ROLLBACK',
  'ROWCOUNT',
  'ROWGUIDCOL',
  'RULE',
  'SAVE',
  'SCHEMA',
  'SECURITYAUDIT',
  'SELECT',
  'SEMANTICKEYPHRASETABLE',
  'SEMANTICSIMILARITYDETAILSTABLE',
  'SEMANTICSIMILARITYTABLE',
  'SESSION_USER',
  'SET',
  'SETUSER',
  'SHUTDOWN',
  'SOME',
  'STATISTICS',
  'SYSTEM_USER',
  'TABLE',
  'TABLESAMPLE',
  'TEXTSIZE',
  'THEN',
  'TO',
  'TOP',
  'TRAN',
  'TRANSACTION',
  'TRIGGER',
  'TRUNCATE',
  'TRY_CONVERT',
  'TSEQUAL',
  'UNION',
  'UNIQUE',
  'UNPIVOT',
  'UPDATE',
  'UPDATETEXT',
  'USE',
  'USER',
  'VALUES',
  'VIEW',
  'WAITFOR',
  'WHERE',
  'WHILE',
  'WITH',
  'WITHIN GROUP',
  'WRITETEXT',
  // https://learn.microsoft.com/en-us/sql/t-sql/queries/output-clause-transact-sql?view=sql-server-ver16#action
  '$ACTION',
];

export const dataTypes: string[] = [
  // https://learn.microsoft.com/en-us/sql/t-sql/data-types/data-types-transact-sql?view=sql-server-ver15
  'BINARY',
  'BIT',
  'CHAR',
  'CHAR',
  'CHARACTER',
  'DATE',
  'DATETIME2',
  'DATETIMEOFFSET',
  'DEC',
  'DECIMAL',
  'DOUBLE',
  'FLOAT',
  'INT',
  'INTEGER',
  'NATIONAL',
  'NCHAR',
  'NUMERIC',
  'NVARCHAR',
  'PRECISION',
  'REAL',
  'SMALLINT',
  'TIME',
  'TIMESTAMP',
  'VARBINARY',
  'VARCHAR',
];
